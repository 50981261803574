 import {  createStore  } from 'vuex'
 import createPersistedState from 'vuex-persistedstate'

 const vuex = createStore({
     plugins: [createPersistedState()],
     state: {
         token: null,
         user: null,
         company: null,
         commercial: null
     },

     mutations: {

         save_Company (state, o) {
             state.company = o
         },

         save_commercial (state, o) {
             state.commercial = o
         },

         save_token (state, position) {
             state.token = position
         },

         save_user (state, position) {
             state.user = position
         },

         logout (state) {
             state.token = null

             state.temporal = null
         },
     },

     actions: {
         saveCompany ({ commit }, d) {
             commit('save_Company', d)
         },
         saveCommercial ({ commit }, d) {
             commit('save_commercial', d)
         },
         saveToken ({ commit }, c) {
             commit('save_token', c)
         },
         saveUser ({ commit }, c) {
             commit('save_user', c)
         },
         logout ({ commit }, e) {
             commit('logout', e)
         },
     },

     getters: {
         token: state => state.token,
         trackCompany: state => state.company,
         trackCommercial: state => state.commercial,
         trackUser: state => state.user,
     }
 })

 export default vuex
